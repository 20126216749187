@charset "utf-8";
/* CSS Document */

/*=================================================*
 * 全体共通                                         *
 *=================================================*/
body {
  /* font-family:'HelveticaNeue','Arial', sans-serif; */
  font-family: var(--font_family);

  position: relative;
  margin: 0;
  height: 100vh;
  color: var(--clr_body);
  overflow: hidden;
}

select {
  padding: 1px;
  background-color: var(--clr_base_white);
  border: 2px solid var(--clr_base_white);
  outline: none;
  border-radius: 0px;
  font-size: var(--fsize_base);
  margin-top: -1px;
  margin-left: 5px;
  color: var(--clr_base_black);
  cursor: pointer;
}
select:focus {
  border: 2px solid var(--clr_base_strong) !important;
}

select option {
  color: var(--clr_base_black);
}

input[type="radio"].opt__radio {
  position: relative;
  width: calc(var(--fsize_base) + 5px);
  height: calc(var(--fsize_base) + 5px);
  top: -2px;
  cursor: pointer;
  padding: 0;
  margin: 4px;
}
label.opt__label {
  color: var(--clr_si_item);
  display: inline-block;
  font-size: var(--fsize_base);
  cursor: pointer;
  padding-right: 4px;
}

.nondisp {
  display: none !important;
}
