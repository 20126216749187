/*------------------------------------------*
 * 画面固有定義                              *
 *------------------------------------------*/
.asiato {
  position: absolute;
  width: 1.5%;
}
div.logo {
  text-align: center;
  margin: 20px 20px 40px 20px;
}
div.logo img {
  /* width:180px; */
  height: 100px;
}
div.logo span {
  display: block;
  font-size: var(--fsize_logo_system);
  margin-top: 12px;
  font-weight: 700;
  color: var(--clr_logo_system);
  /* letter-spacing: 1; */
}
div.logo.logo-active span {
  color: var(--clr_logo_active) !important; /* #7f0200 #550201 */
  transition: all 0.1s ease;
}
#formWrapper {
  background: var(--bkclr_login);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s ease;
}
.darken-bg {
  background: var(--bkclr_login_focus) !important;
  transition: all 0.5s ease;
} /* #7f0200 #550201 */

div#form {
  position: absolute;
  width: 416px;
  height: 464px;
  /* height:auto; */
  background-color: var(--bkclr_login_form);
  margin: auto;
  border-radius: 16px;
  padding: 24px;
  left: calc(50% - 416px / 2);
  top: calc(50% - 464px / 2);
  /* margin-left:-180px; */
  /* margin-top:-200px; */
}
div.form-item {
  position: relative;
  display: block;
  margin-bottom: 20px;
  text-align: center;
}
input {
  transition: all 0.2s ease;
}
input.form-style {
  color: var(--clr_login_input);
  display: block;
  width: 90%;
  height: 44px;
  padding: 5px 5%;
  border: 1px solid var(--clr_login_inputbox);
  -moz-border-radius: 27px;
  -webkit-border-radius: 27px;
  border-radius: 27px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #fff;
  /* font-family:'HelveticaNeue','Arial', sans-serif; */
  font-size: var(--fsize_base);
  letter-spacing: 0.8px;
  margin: auto;
}
div.form-item .form-style:focus {
  outline: none;
  border: 1px solid var(--clr_ipt_focus);
  color: var(--clr_ipt_focus);
}
div.form-item p.formLabel {
  position: absolute;
  left: 38px;
  top: 14px;
  transition: all 0.4s ease;
  color: var(--clr_login_formlabel);
}
.formTop {
  top: -6px !important;
  left: 26px;
  background-color: var(--bkclr_login_form);
  padding: 0 5px;
  font-size: var(--fsize_base);
  color: var(--clr_ipt_focus) !important;
}
button.login,
a.login {
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  /* float:right; */
  width: 112px;
  height: 37px;
  -moz-border-radius: 19px;
  -webkit-border-radius: 19px;
  border-radius: 19px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: var(--bkclr_btn);
  border: 1px solid var(--bkclr_btn);
  border: none;
  color: var(--clr_btn);
  font-weight: bold;
  font-size: var(--fsize_base);
  margin-top: 30px;
}
button.login:hover,
a.login:hover {
  background-color: var(--clr_btn);
  border: 1px solid var(--bkclr_btn);
  color: var(--bkclr_btn);
  cursor: pointer;
}
button.login:focus,
a.login:focus {
  outline: none;
}
