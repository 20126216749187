@charset "utf-8";
/* CSS Document */

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");

/*=================================================*
 * 個別定義                                         *
 *=================================================*/
:root {
  /*------------------------*
     * 個別基準                *
     *------------------------*/
  --font_family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif, Helvetica;
  --fsize_base: 12px; /* ベースサイズ */
  --fsize_base02: 13px; /* ベースサイズ(少し強調) */
  --lineh_base: 16px; /* ベース文字高さ */
  --clr_base: #7f0200; /* ベースカラー01 */
  --clr_base02: #680300; /* ベースカラー02 #580300 */
  --clr_base03: #b60300; /* ベースカラー03 */
  --clr_base04: #9f0710; /* ベースカラー04 */
  --clr_base05: #f27e7b; /* ベースカラー05 */
  --clr_base06: #ffeeee; /* ベースカラー06 */
  --clr_base_white: #fff; /* ベースホワイト */
  --clr_base_black: #393939; /* ベースブラック */ /* #393939 */
  --clr_base_blue: #9ca9ce; /* ベースブルー */
  --clr_base_gray01: #bbb; /* ベースグレー01 */
  --clr_base_gray02: #8a8a8a; /* ベースグレー02 */
  --clr_base_gray03: #ccc; /* ベースグレー03 */
  --clr_base_gray04: #f0f0f0; /* ベースグレー04 */
  --clr_base_gray05: #e0e0e0; /* ベースグレー05 */
  --clr_base_gray06: #ddd; /* ベースグレー06 */
  --clr_base_gray07: #555; /* ベースグレー07 */
  --clr_base_strong: #58bff6; /* ベース強調色01 */
  --clr_base_strong02: #4787ff; /* ベース強調色02 */
  --clr_base_strong03: #c2deff; /* ベース強調色03 */

  /*------------------------*
     * 個別共通                *
     *------------------------*/
  --clr_body: var(--clr_base_black); /* ボディ基準色 */
  --clr_ipt_focus: var(--clr_base_strong); /* 強調色 */
  --clr_btn: var(--clr_base_white); /* ボタン文字色 */

  --bkclr_btn: var(--clr_base); /* ボタン背景色 */

  /*------------------------*
     * ログイン画面            *
     *------------------------*/
  --fsize_logo_system: 28px; /*  ロゴ下部「SYSTEM」文字サイズ */
  --clr_logo_system: var(--clr_base_strong); /* ロゴ下部「SYSTEM」文字色 */
  --clr_logo_active: var(--clr_base); /* ロゴ下部「SYSTEM」強調色 */
  --clr_login_formlabel: var(--clr_base_gray01); /* ラベル名文字色 */
  --clr_login_input: var(--clr_base_gray02); /* 入力文字色 */
  --clr_login_inputbox: var(--clr_base_gray03); /* 入力領域の枠線色 */

  --bkclr_login: rgba(0, 0, 0, 0.85); /* ログイン画面 背景色 */
  --bkclr_login_focus: var(--clr_base02); /* ログイン画面 背景色 */
  --bkclr_login_form: var(--clr_base_white); /* ログイン画面 フォーム背景色 */

  /*------------------------*
     * メニュー画面            *
     *------------------------*/
  --fsize_menu: 52px; /* メニュー 文字サイズ */
  --clr_menu01: var(--clr_base_black); /* メニュー 文字色01 */
  --clr_menu02: var(--clr_base_white); /* メニュー 文字色02 */

  --bkclr_menu01: var(--clr_base_white); /* メニュー 背景色01 */
  --bkclr_menu02: var(--clr_base); /* メニュー 背景色02 */

  /*------------------------*
     * メイン画面共通          *
     *------------------------*/
  /* ヘッダー部 */
  --clr_header: var(--clr_base_black); /* 全体の文字色 */
  --bkclr_header: var(--clr_base_white); /* 全体の背景色 */
  --bkclr_logo: var(--clr_base_white); /* ロゴ部分の背景色 */
  --bkclr_header_hover: var(--clr_bsee03); /* ヘッダ部のホバー時色 */
  --fsize_backpage: 22px; /* 戻るボタン 文字サイズ */
  --fsize_menuline: 13px; /* メニューライン全体 文字サイズ */
  --bkclr_menuline: var(--clr_base); /* メニューライン 全体背景色 */
  --clr_menuline: var(--clr_base_white); /* メニューライン 全体文字色 */
  --fsize_menu_hover: 14px; /* メニューホバー時 文字サイズ */
  --fsize_menuicon: 15px; /* メニューアイコン 文字サイズ */
  --clr_menuicon: var(--clr_base_white); /* メニューアイコン 文字色 */
  --clr_menuicon_hover: var(
    --clr_base_strong02
  ); /* メニューアイコン ホバー時文字色 */
  --bkclr_menu_hover: var(--clr_base_strong03); /* メニュー ホバー時背景色 */
  --clr_menuline_border: var(--clr_base_gray03); /* メニュー仕切り線 枠線色 */

  /*------------------------*
     * comam様 固有            *
     *------------------------*/
  --clr_orgmenu_border: var(
    --clr_base_white
  ); /* オリジナルメニュー仕切り線 枠線色 */
  --bkclr_orgmenu_hover: var(
    --clr_bsee_strong03
  ); /* オリジナルメニュー ホバー時背景色 */

  /*------------------------*
     * 機能ライン              *
     *------------------------*/
  --fsize_funcline: var(--fsize_base); /* 機能ライン 文字サイズ */
  --clr_funcline: var(--clr_base_white); /* 機能ライン 文字色 */
  --fsize_funcline_ttl: var(--fsize_base02); /* 機能ライン タイトル文字サイズ */
  --bkclr_functab: var(--clr_base_white); /* 機能タブ ラベル名 背景色 */
  --clr_functab: var(--clr_base_black); /* 機能タブ ラベル名 文字色 */
  --fsize_functab: var(--fsize_base); /* 機能タブ ラベル名 文字サイズ */
  --clr_functab_border: var(--clr_base_gray03); /* 機能タブ 仕切り線 枠線色 */
  --fsize_functab_hover: calc(
    var(--fsize_functab) + 1px
  ); /* 機能タブ ラベル名 ホバー時文字サイズ */
  --bkclr_functab_check: var(--clr_base_strong02); /* 機能タブ 選択時 背景色 */
  --clr_functab_check: var(--clr_base_white); /* 機能タブ 選択時 文字色 */
  --bkclr_functtl: var(--clr_base_gray05); /* 選択機能タイトル 背景色 */
  --clr_functtl_notes: var(--clr_base); /* 選択機能タイトル 表示条件 文字色 */

  /*------------------------*
     * 一覧画面                *
     *------------------------*/
  --meisai_h: 20px; /* 明細データ（高さ）*/
  /* 明細データ（幅） */
  --meisai_item_01: 5%;
  --meisai_item_02: 5%;
  --meisai_item_03: 5%;
  --meisai_item_04: 10%;
  --meisai_item_05: 15%;
  --meisai_item_06: 3%;
  --meisai_item_07: 12%;
  --meisai_item_08: 8%;
  --meisai_item_09: 10%;
  --meisai_item_10: 8%;
  --meisai_item_11: 0px;
  --meisai_item_12: 0px;
  --meisai_item_13: 0px;
  --meisai_item_14: 0px;
  --meisai_item_15: 0px;
  /* 明細データ（配置） */
  --meisai_talgn_01: center;
  --meisai_talgn_02: center;
  --meisai_talgn_03: center;
  --meisai_talgn_04: left;
  --meisai_talgn_05: left;
  --meisai_talgn_06: center;
  --meisai_talgn_07: left;
  --meisai_talgn_08: center;
  --meisai_talgn_09: center;
  --meisai_talgn_10: center;
  --meisai_talgn_11: center;
  --meisai_talgn_12: center;
  --meisai_talgn_13: center;
  --meisai_talgn_14: center;
  --meisai_talgn_15: center;

  /* データ領域 */
  --clr_dt_sibori_border: var(--clr_base_gray03); /* 絞込表示select 枠線色 */
  --clr_dt_tablst_border: var(--clr_base_gray03); /* 表示タブリスト 枠線色 */
  --clr_dt_tab: var(--clr_base_white); /* 表示タブ 文字色 */
  --clr_dt_tab_check: var(--clr_base_white); /* 表示タブ 選択時 文字色 */
  --clr_dt_meisai_border: var(--clr_base_gray03); /* 明細 枠線色 */
  --clr_dt_meisai: var(--clr_base_black); /* 明細 文字色 */
  --clr_dt_meittl_border: var(--clr_base_white); /* 明細タイトル 枠線色 */
  --bkclr_dt_tab: #7f6060; /* 表示タブ 背景色 */
  --bkclr_dt_tab_check: var(--clr_base04); /* 表示タブ 選択時 背景色 */
  --bkclr_dt_meisai: var(--clr_base_white); /* 明細 背景色 */
  --bkclr_dt_meisai_even: #ebffff; /* 明細 偶数 背景色 */
  --bkclr_dt_meittl: var(--clr_base_gray03); /* 明細タイトル 背景色 */

  /* ページ制御 */
  --clr_pagebtn: var(--clr_base_black); /* ページボタン 文字色 */
  --clr_pagebtn_hover: var(--clr_base_white); /* ページボタン ホバー時 文字色 */
  --clr_pageslct_hover: var(
    --clr_base_white
  ); /* ページボタン ホバー時 文字色 */
  --clr_pageslct_focus: var(
    --clr_base_black
  ); /* ページボタン フォーカス時 文字色 */
  --clr_pageslct_border: var(--clr_base_gray03); /* ページコンボ 枠線色 */
  --bkclr_pagebtn: var(--clr_base_gray06); /* ページボタン 背景色 */
  --bkclr_pagebtn_hover: var(--clr_base04); /* ページボタン ホバー時 背景色 */
  --bkclr_pageslct: var(--clr_base_white); /* ページコンボ 背景色 */
  --bkclr_pageslct_hover: var(--clr_base04); /* ページコンボ ホバー時 背景色 */
  --bkclr_pageslct_focus: var(
    --clr_base_white
  ); /* ページコンボ フォーカス時 背景色 */

  /*------------------------*
     * 検索・登録編集等実行画面 *
     *------------------------*/
  /* ボタン */
  --clr_actbtn: var(--clr_base_white); /* ボタン 文字色 */
  --bkclr_actbtn: var(--clr_base_gray07); /* ボタン 背景色 */
  --bkclr_actbtn_run: var(--clr_base04); /* 実行ボタン 背景色 */

  /* 検索制御 */
  --clr_itemlst_border: var(--clr_base_gray02); /* 項目 枠線 */
  --bkclr_itemname: var(--clr_base_gray04); /* 項目名 背景色 */

  /* 検索項目 */
  --clr_si_item: var(--clr_base_black); /* 検索項目 文字色 */
  --clr_si_border: var(--clr_base_gray07); /* 検索項目 枠線 */
  --clr_si_checkline: var(--clr_base_white); /* 検索項目 チェック時 レ点 */
  --bkclr_si_item: var(--clr_base_white); /* 検索項目 背景色 */

  /*------------------------*
     * ポップアップ画面         *
     *------------------------*/
  --clr_popup_border: var(--clr_base_gray03); /* ポップアップ画面 共通枠線 */
}
